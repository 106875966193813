import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { I18n } from '../../i18n/I18n';
import { SharedFiles } from '../file/SharedFiles';
class FolderEntityWithFlatTitle extends FolderEntity {
}
export function FolderSelector(props) {
    const [searchValue, setSearchValue] = useState('');
    const getFolderList = () => {
        const { folders, onSelect } = props;
        const { excludeFolders } = SharedFiles;
        const options = [];
        if (excludeFolders == null || excludeFolders.indexOf('private') === -1) {
            options.push({
                data: 'to_folder_private',
                thumbnail: {
                    width: 40,
                    thumbnail: <Icon toolTip="" icon="folder-lock" backgroundColor="rgba(0,0,0,0.12)"/>,
                },
                title: I18n.m.getMessage('selectorFolderPrivate'),
                onPress: onSelect(''),
            });
        }
        if (excludeFolders == null || excludeFolders.indexOf('public') === -1) {
            options.push({
                data: 'to_folder_public',
                thumbnail: {
                    width: 40,
                    thumbnail: <Icon toolTip="" icon="folder-home" backgroundColor="rgba(0,0,0,0.12)"/>,
                },
                title: I18n.m.getMessage('selectorFolderPublic'),
                onPress: onSelect('public'),
            });
        }
        folders.forEach((e) => {
            if (excludeFolders == null || excludeFolders.indexOf(e.id) === -1) {
                options.push({
                    data: `folder_${e.id}`,
                    thumbnail: {
                        width: 40,
                        thumbnail: <Icon toolTip="" icon="folder" backgroundColor="rgba(0,0,0,0.12)"/>,
                    },
                    title: e.title,
                    secondTextLine: e.title !== e.flatTitle ? e.flatTitle : '',
                    onPress: onSelect(e.id),
                });
            }
        });
        const visibleOptions = [];
        options.forEach((o) => {
            if (searchValue.length === 0 || o.title.toLowerCase().includes(searchValue))
                visibleOptions.push(<ListItem {...o}/>);
        });
        return visibleOptions;
    };
    return (<ScrollView style={{ width: '100%', height: '100%' }}>
      <View style={{ height: 56, paddingHorizontal: ThemeManager.style.contentPaddingValue, paddingVertical: 4 }}>
        <SearchBar searchOnChange={(s) => setSearchValue(s.toLowerCase())} searchBarValue={searchValue} maxWidth={526}/>
      </View>
      <View>{getFolderList()}</View>
    </ScrollView>);
}
