"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateExtraPayTrackings = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var TimeTrackingEntity_1 = require("../../../query/entities/TimeTrackingEntity");
var CreateExtraPayTracking_1 = require("./CreateExtraPayTracking");
var CreateExtraPayTrackings = function (_CommandRoot_1$Comman) {
  function CreateExtraPayTrackings(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v4();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateExtraPayTrackings);
    _this = _callSuper(this, CreateExtraPayTrackings, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'CreateExtraPayTrackings';
    return _this;
  }
  (0, _inherits2.default)(CreateExtraPayTrackings, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(CreateExtraPayTrackings, [{
    key: "getExtraPay",
    value: function () {
      var _getExtraPay = (0, _asyncToGenerator2.default)(function* () {
        if (this._extraPay == null) this._extraPay = yield CommandReadModels_1.CommandReadModels.extraPay.getById(this.data.extraPayId);
        return this._extraPay;
      });
      function getExtraPay() {
        return _getExtraPay.apply(this, arguments);
      }
      return getExtraPay;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var company = yield CommandReadModels_1.CommandReadModels.company.getById(this.data.companyId);
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${company.id}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length > 0) this._me = companyMember[0];
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && companyMember[0].payroll) return true;
        var promisesPeriodCheck = [];
        this.data.memberIds.forEach(function (u, _index) {
          promisesPeriodCheck.push(_this2.checkForRecordingPeriod(u));
        });
        yield Promise.all(promisesPeriodCheck);
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive') return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "checkForRecordingPeriod",
    value: function () {
      var _checkForRecordingPeriod = (0, _asyncToGenerator2.default)(function* (memberId) {
        var member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(memberId);
        if (member != null) {
          var period = yield TimeTrackingEntity_1.TimeTrackingEntity.getRecordingPeriod(member);
          var starts = new Date(this.data.day);
          if (starts.getTime() < period.start.getTime() || starts.getTime() > period.end.getTime()) {
            throw {
              message: 'Das Datum liegt nicht im möglichen Erfassungszeitraum',
              messageCode: 'timeTrackingNotInRecordingPeriod'
            };
          }
        }
      });
      function checkForRecordingPeriod(_x) {
        return _checkForRecordingPeriod.apply(this, arguments);
      }
      return checkForRecordingPeriod;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return [];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        var data = this.data;
        if (key == null || key === 'companyId') {
          if (data.companyId == null || data.companyId.length === 0) {
            errors.push({
              message: 'Die Firma wird benötigt',
              messageCode: 'required',
              key: 'companyId'
            });
          }
        }
        if (key == null || key === 'costCenterId' || key === 'projectId') {
          if (data.costCenterId == null || data.costCenterId.length === 0) {
            errors.push({
              message: 'Die Kostenstelle wird benötigt',
              messageCode: 'required',
              key: 'costCenterId'
            });
          } else {
            try {
              var cc = yield CommandReadModels_1.CommandReadModels.costCenter.getById(data.costCenterId);
              if (cc.costType === 'costUnit') {
                if (data.projectId == null) {
                  errors.push({
                    message: 'Wenn ein Kostenträger gewählt ist, wird die Projekt id benötigt',
                    messageCode: 'costUnitNeedsProject',
                    key: 'projectId'
                  });
                }
                var isIn = cc.projectIds == null ? -1 : cc.projectIds.findIndex(function (p) {
                  return p === data.projectId;
                });
                if (isIn === -1) {
                  errors.push({
                    message: 'Das Projekt ist dem Kostenträger nicht zugeordnet',
                    messageCode: 'projectNotInCostUnit',
                    key: 'projectId'
                  });
                }
              }
            } catch (e) {
              errors.push({
                message: 'Diese Kostenstelle existiert nicht',
                messageCode: 'costCenterIdNotFound',
                key: 'costCenterId'
              });
            }
          }
        }
        if (key == null || key === 'memberIds') {
          if (data.memberIds == null || data.memberIds.length === 0) {
            errors.push({
              message: 'Min. 1 Benutzer wird benötigt',
              messageCode: 'required',
              key: 'memberIds'
            });
          }
        }
        if (key == null || key === 'day') {
          if (data.day == null) {
            errors.push({
              message: 'Das Datum wird benötigt',
              messageCode: 'required',
              key: 'starts'
            });
          }
        }
        if (key == null || key === 'extraPayId') {
          if (data.extraPayId == null || data.extraPayId.length === 0) {
            errors.push({
              message: 'Die Zulagenart wird benötigt',
              messageCode: 'required',
              key: 'extraPayId'
            });
          } else {
            var extraPay = yield this.getExtraPay();
            if (extraPay.onceADay && this.data.memberIds != null && this.data.memberIds.length > 0) {
              var day = new Date(this.data.day);
              var start = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0, 0);
              var end = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1, 0, 0, 0);
              for (var memberId of this.data.memberIds) {
                var exists = yield CommandReadModels_1.CommandReadModels.extraPayTracking.get({
                  filter: `memberId eq '${memberId}' and extraPayId eq '${this.data.extraPayId}' and deleted ne true and day gt ${start.toISOString()} and day lt ${end.toISOString()}`
                });
                if (exists.length > 0 && exists[0].id !== this.entityId) {
                  errors.push({
                    message: 'wird benötigt',
                    messageCode: 'extraOnlyOnceADay',
                    key: 'extraField'
                  });
                }
              }
            }
            if (extraPay.fieldType === 'number') {
              if (this.data.extraField == null) {
                errors.push({
                  message: 'wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (typeof data.extraField !== 'number') {
                errors.push({
                  message: 'Zahl wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (extraPay.field != null && extraPay.field.min != null && data.extraField < extraPay.field.min) {
                errors.push({
                  message: `Der Wert ist zu klein, min ${extraPay.field.min}`,
                  messageCode: 'numberToSmall',
                  messageData: {
                    value: extraPay.field.min
                  },
                  key: 'extraField'
                });
              } else if (extraPay.field != null && extraPay.field.max != null && data.extraField > extraPay.field.max) {
                errors.push({
                  message: `Der Wert ist zu groß, maximum ${extraPay.field.min}`,
                  messageCode: 'numberToBig',
                  messageData: {
                    value: extraPay.field.max
                  },
                  key: 'extraField'
                });
              }
            } else if (extraPay.fieldType === 'duration') {
              var _data$extraField, _data$extraField2;
              if (this.data.extraField == null) {
                errors.push({
                  message: 'wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (typeof data.extraField === 'number') {
                errors.push({
                  message: 'Zeitspanne wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (((_data$extraField = data.extraField) == null ? void 0 : _data$extraField.from) == null) {
                errors.push({
                  message: 'Zeit wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (((_data$extraField2 = data.extraField) == null ? void 0 : _data$extraField2.to) == null) {
                errors.push({
                  message: 'Zeit wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              }
            }
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validate(_x2) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "execute",
    value: function () {
      var _execute = (0, _asyncToGenerator2.default)(function* (onStore) {
        if (this.executed) {
          return;
        }
        this.executed = true;
        var canI = yield this.canI();
        if (canI !== true) {
          throw canI;
        }
        yield this.validate();
        var commands = [];
        var restrictMembersForUser = new Set();
        if (this._me != null) {
          if (this._me.canTimeTrackFor === 'self') restrictMembersForUser.add(this._me.id);else if (this._me.canTimeTrackFor === 'group') {
            var companyMembers = yield CommandReadModels_1.CommandReadModels.companyMember.get({
              filter: `companyId eq '${this.data.companyId}' and groupId eq '${this._me.groupId}' and deleted ne true`
            });
            companyMembers.forEach(function (m) {
              restrictMembersForUser.add(m.id);
            });
          }
        }
        for (var u of this.data.memberIds) {
          if (restrictMembersForUser.size === 0 || restrictMembersForUser.has(u)) commands.push(new CreateExtraPayTracking_1.CreateExtraPayTracking(Object.assign({}, this.data, {
            memberId: u
          })));
        }
        for (var c of commands) {
          yield c.validate();
          yield c.execute(onStore);
        }
        this.result = true;
      });
      function execute(_x3) {
        return _execute.apply(this, arguments);
      }
      return execute;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.CreateExtraPayTrackings = CreateExtraPayTrackings;