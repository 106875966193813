"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateUserProjectSettings = void 0;
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var Config_1 = require("../../../../Config");
var UserProjectSettingsCreated_1 = require("../../../events/project/dashboard/UserProjectSettingsCreated");
var ClientProjectCommands_1 = require("../../_root/ClientProjectCommands");
var CreateUserProjectSettings = function (_ClientProjectCommand) {
  function CreateUserProjectSettings(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateUserProjectSettings);
    _this = _callSuper(this, CreateUserProjectSettings, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'CreateUserProjectSettings';
    return _this;
  }
  (0, _inherits2.default)(CreateUserProjectSettings, _ClientProjectCommand);
  return (0, _createClass2.default)(CreateUserProjectSettings, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield _superPropGet(CreateUserProjectSettings, "checkProjectStatusAndGeneralAccess", this, 3)([this.data.projectId, userId]);
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var avialableEntities = yield ReadModels_1.ReadModels.get('UserProjectSettings').get({
          filter: `userId eq '${userId}' and projectId eq '${this.data.projectId}'`
        });
        if (avialableEntities.length > 0) {
          throw {
            key: 'projectId',
            messageCode: 'alreadyExists',
            message: 'UserProjectSettings für dieses Projekt bereits vorhanden'
          };
        }
        if (key == null || key === 'dashboard') {
          var _this$data$dashboard;
          if (this.data.dashboard != null && ((_this$data$dashboard = this.data.dashboard) == null ? void 0 : _this$data$dashboard.length) > Config_1.ConfigHandler.config.string_Huge) {
            throw {
              key: 'dashboard',
              messageCode: 'required',
              message: 'Eingabe ist zu lang'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        return new UserProjectSettingsCreated_1.UserProjectSettingsCreated(this.entityId, {
          userId: userId,
          projectId: this.data.projectId,
          dashboard: this.data.dashboard
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.CreateUserProjectSettings = CreateUserProjectSettings;