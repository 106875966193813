import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Keyboard, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { WaitFor } from 'cqrs-shared/src/WaitFor';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { Url } from 'cqrs-shared/src/uri/Url';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
import { ProjectUploadButton } from '../file/ProjectUploadButton';
export class PlanUploadPU extends PureComponent {
    constructor(props) {
        super(props);
        this.title = I18n.m.getMessage('planUploadTitle');
        this.planfilesChanged = () => {
            this.getCurrentPlanUploads().catch((err) => console.debug(err));
        };
        this.onLayout = (e) => {
            let width = e.nativeEvent.layout.width > 0 ? e.nativeEvent.layout.width : 0.9 * ResizeEvent.current.windowWidth;
            width = Math.min(width, 300);
            this.setState({ maxWidth: width });
        };
        this.onUploading = (_uploading) => {
            this.getCurrentPlanUploads().catch((err) => console.debug(err));
        };
        this.pressTempPlan = (e) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                Routing.instance.openDialog('tempPlans', { projectId }, true)(e);
            }
        };
        this.state = {
            completedFiles: [],
            isLoading: true,
            maxWidth: 300,
            buttonTypes: 'save',
            uploadStatusObject: {},
        };
    }
    changeDialogButtonsToClose() {
        this.setState({ buttonTypes: 'close' });
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'PlanFiles',
            attachKey: 'plansUpload',
            callback: this.planfilesChanged,
        });
        this.getCurrentPlanUploads().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('PlanFiles', 'plansUpload');
        if (Keyboard != null) {
            Keyboard.dismiss();
        }
    }
    renderSpinner() {
        return (<View onLayout={this.onLayout} style={{
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                minHeight: 242,
            }}>
        <Spinner />
      </View>);
    }
    renderOfflineMessage() {
        return (<View onLayout={this.onLayout} style={{
                ...ThemeManager.style.contentPadding,
                paddingBottom: 4,
            }}>
        <MaterialText centeredText>{I18n.m.getMessage('commandOfflineNotPossible')}</MaterialText>
        <View style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <View>
            <ContainedButton onPress={Routing.instance.goBackButton} title={I18n.m.getMessage('ok')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          </View>
        </View>
      </View>);
    }
    renderButtons() {
        const { completedFiles, buttonTypes } = this.state;
        if (buttonTypes === 'close') {
            return [
                <ContainedButton key="close" onPress={Routing.instance.closeDialog(true)} title={I18n.m.getMessage('close')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ];
        }
        return [
            <ContainedButton key="close" onPress={Routing.instance.closeDialog(true)} title={I18n.m.getMessage('close')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            <ContainedButton key={`save_${completedFiles.length === 0}`} disabled={completedFiles.length === 0} onPress={this.pressTempPlan} title={I18n.m.getMessage('next')} backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF"/>,
        ];
    }
    render() {
        const { completedFiles } = this.state;
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (this.state.isLoading || projectId == null) {
            return this.renderSpinner();
        }
        if (!AuthClient.instance.serverConnection.isConnected) {
            return this.renderOfflineMessage();
        }
        const uploaded = [];
        for (const f of completedFiles) {
            uploaded.push({ name: f.orgFileName, targetUrl: `${UpmeshClient.instance.url}/planfiles/upload/${f.id}` });
        }
        const height = ResizeEvent.current.windowHeight <= ThemeManager.style.breakpointM
            ? ResizeEvent.current.contentHeight - 128
            : ResizeEvent.current.windowHeight * 0.9 - 128;
        return [
            <DialogTitle key="title">{this.title}</DialogTitle>,
            <View key="content" collapsable={false} style={{
                    height,
                    paddingTop: 0,
                    padding: ThemeManager.style.contentPaddingValue,
                    overflow: 'hidden',
                    flex: 1,
                }}>
        <ProjectUploadButton endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/planfiles/upload/`)} uploadText={I18n.m.getMessage('planUploadTextButton')} uploadIcon="cloud-upload-outline" multiple id={`${projectId}_plan_upload`} filter={['pdf', 'images']} data={{ projectId }} uploaded={uploaded} onUploading={this.onUploading} selfManageUploads/>
      </View>,
            <DialogActions onBottom key="buttons">
        {this.renderButtons()}
      </DialogActions>,
        ];
    }
    updateDialogButtons() {
        this.setState({ buttonTypes: 'save' });
    }
    async getCurrentPlanUploads() {
        await WaitFor.instance.waitFor(() => AuthClient.instance.ready);
        if (AuthClient.instance.ready) {
            await WaitFor.instance.waitFor(() => CurrentUser.userId != null);
            await WaitFor.instance.waitFor(() => CurrentProject.instance.getCurrentProjectId() != null);
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                const completedFiles = await UpmeshClient.instance.modals.planFiles.get({
                    filter: `projectId eq '${projectId}' and uploaded eq true and processed ne true`,
                    orderby: 'createdAt DESC',
                });
                this.setState({
                    completedFiles: [...completedFiles],
                    isLoading: false,
                });
            }
            else {
                Routing.instance.closeDialog(true)(null);
            }
        }
    }
}
PlanUploadPU.defaultProps = {
    data: null,
};
